<template>
  <DetailLayout :area="property.sector">
    <div class="container">
      <div class="row body">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <h3 class="text-success text-center text-lg-start">
              {{ property.title }}
            </h3>
            <button class="bg-transparent border-0">
              <svg
                @click="addToFavourite"
                style="width: 30px; color: grey"
                :fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <hr class="d-none d-lg-block border-2 border-success text-success" />
          <div class="detail__short-desc my-3">
            <div class="row detail__short-desc-point">
              <div class="col-lg-2">
                <div class="col-lg-2 p-0">
                  <div
                    class="
                      circle
                      bg-danger
                      font-weight-bolder
                      d-flex
                      flex-lg-column
                    "
                  >
                    <span class="h6 mb-0" style="white-space: nowrap">
                      {{ $t("property_details.Recommendation") }}
                    </span>
                    <span>Point!!</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 mt-3 mt-lg-0">
                <p>
                  {{ property.recommendation }}
                </p>
              </div>
            </div>
            <div
              class="
                row
                detail__short-desc-option
                d-flex
                align-content-center
                justify-content-center justify-content-lg-start
              "
            >
              <div class="col-lg-2">
                <div class="circle bg-success font-weight-bolder">
                  <span class="h5 mb-0">
                    {{ $t("property_details.Neighborhood") }}
                  </span>
                </div>
              </div>
              <div
                class="
                  col-lg-9
                  buttons
                  mt-3 mt-lg-0
                  justify-content-center justify-content-lg-start
                "
              >
                <span
                  class="btn btn-outline-success"
                  v-for="(item, index) in property.condition"
                  :key="index"
                >
                  {{ $t(`property_details.Surrounding_content.${item}`) }}
                </span>
              </div>
            </div>
          </div>
          <p style="text-align: right">
            {{ $t("explore.release_date") }}: {{ property.createdAt | format }}
          </p>

          <!-- Carousel -->
          <div class="detail__slide">
            <b-carousel
              id="carousel"
              controls
              :interval="4000"
              :value="slideIndex"
            >
              <div v-for="(img, index) in property.images" :key="index">
                <b-carousel-slide
                  :img-src="url + img"
                  img-alt="img"
                ></b-carousel-slide>
              </div>
            </b-carousel>
            <div class="container mt-3">
              <div class="row carousel_images p-0">
                <b-img
                  v-for="(img, index) in property.images"
                  :key="index"
                  :src="url + img"
                  @click="clickImg(index)"
                  class="carousel_image col-3 p-2"
                ></b-img>
              </div>
            </div>
          </div>

          <!-- property Detail -->

          <div class="detail__specific mt-5">
            <div class="detail_header mb-5 position-relative">
              <div class="icon">
                <i class="fas fa-home fa-3x text-success"></i>
              </div>
              <h3 class="text-white text-uppercase font-weight-bold">
                {{ $t("property_details.Details") }}
              </h3>
            </div>
             
            <div class="details_wrapper px-3">

              <div class="row" v-if="property.property_id">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.id") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>
                      {{property.property_id }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Type") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>
                      {{ $t(`property_details.Type_content.${property.type}`) }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Title") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>{{ property.title }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Sector") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>{{ property.sector }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Address") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>{{ property.address }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Area") }} (㎡)</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>{{ formatMoney(property.square, 0) }} ㎡</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Cost") }} (USD)</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>{{ formatMoney(property.cost, 0) }} USD</p>
                  </div>
                </div>
              </div>
              <div class="row" v-if="property.rent_in_vnd">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.rent_in_vnd") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>{{ formatMoney(property.rent_in_vnd, 0) }} VND</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Deposit") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>{{formatMoney(property.deposit, 0) }}</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Contract period") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p>
                      {{
                        $t(
                          `property_details.Contract_period_content.${property.contract_period}`
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Floor plan") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <div>
                    <p class="text-uppercase">{{ property.plan }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div>
                    <p>{{ $t("property_details.Surrounding") }}</p>
                  </div>
                </div>
                <div class="col-9">
                  <p>
                    <span
                      class="surrounding_content"
                      v-for="(item, index) in property.condition"
                      :key="index"
                    >
                      {{ $t(`property_details.Surrounding_content.${item}`) }}
                    </span>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <div class="w-100 h-100">
                    <p class="w-100 h-100 d-flex align-items-center">
                      {{ $t("property_details.Equipment") }}
                    </p>
                  </div>
                </div>
                <div class="col-9">
                  <div class="equipment">
                    <p v-for="(item, index) in property.equipment" :key="index">
                      <span class="dot"></span>
                      <span>{{
                        $t(`property_details.Equipment_content.${item}`)
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Map -->
          <div class="detail__map mt-5">
            <div class="detail_header position-relative mb-5">
              <div class="icon">
                <i class="fas fa-map-marked-alt fa-3x text-success"></i>
              </div>
              <h3 class="text-white text-uppercase font-weight-bold">
                {{ $t("property_details.Map") }}
              </h3>
            </div>
            <div id="map">
              <iframe
                width="100%"
                height="100%"
                style="border: 0"
                loading="lazy"
                allowfullscreen
                :src="src"
              >
              </iframe>
            </div>
          </div>
          <!-- Contact -->
          <div class="detail__contact my-3">
            <div class="card text-center border-radius-lg contact">
              <div
                class="card-header text-white bg-success h3 font-weight-bold"
              >
                {{ $t("property_details.Contact") }}
              </div>
              <div class="card-body pt-2 pb-3">
                <router-link to="/contact-us">
                  <button
                    type="button"
                    class="
                      btn btn-warning
                      font-weight-bold
                      text-white
                      custom-orange-button
                      border-0 border-radius-md
                    "
                  >
                    <span class="h3 font-weight-bold">{{
                      $t("property_details.Contact_button")
                    }}</span>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DetailLayout>
</template>

<script>
import axiosConfig from "../../libs/axiosConfig";
import DetailLayout from "../../layout/main/DetailLayout.vue";
import { mapGetters, mapState } from "vuex";
import { formatMoney } from "../../utils"
export default {
  name: "property-details",
  components: {
    DetailLayout,
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      property: {},
      slideIndex: 0,
      id: this.$route.params.property_id,
      currentColor: "grey",
      src: "",
      equipments: [
        {
          value: "gas-electric",
          text: this.$t("property_create.facility.gas-electric"),
        },

        {
          value: "gas-propane",
          text: this.$t("property_create.facility.gas-propane"),
        },

        {
          value: "laundry-indoor",
          text: this.$t("property_create.facility.laundry-indoor"),
        },

        {
          value: "laundry-outdoor",
          text: this.$t("property_create.facility.laundry-outdoor"),
        },

        {
          value: "tv",
          text: this.$t("property_create.facility.tv"),
        },

        {
          value: "ac",
          text: this.$t("property_create.facility.ac"),
        },

        {
          value: "bathtub",
          text: this.$t("property_create.facility.bathtub"),
        },

        {
          value: "shower",
          text: this.$t("property_create.facility.shower"),
        },

        {
          value: "pet-ok",
          text: this.$t("property_create.facility.pet-ok"),
        },

        {
          value: "no-pet",
          text: this.$t("property_create.facility.no-pet"),
        },

        {
          value: "gym",
          text: this.$t("property_create.facility.gym"),
        },
        {
          value: "swimming-pool",
          text: this.$t("property_create.facility.swimming-pool"),
        },
        {
          value: "fire-detecter",
          text: this.$t("property_create.facility.fire-detector"),
        },
        {
          value: "evacuation",
          text: this.$t("property_create.facility.evacuation"),
        },
        {
          value: "mopet-parking",
          text: this.$t("property_create.facility.mopet-parking"),
        },
        {
          value: "cleaning",
          text: this.$t("property_create.facility.cleaning"),
        },
        {
          value: "elevator",
          text: this.$t("property_create.facility.elevator"),
        },
        {
          value: "security",
          text: this.$t("property_create.facility.security"),
        },
        {
          value: "balcony",
          text: this.$t("property_create.facility.balcony"),
        },
      ],
    };
  },
  created() {
    // authentication
    axiosConfig
      .get("/property/" + this.id)
      .then((response) => {
        this.property = response.data.property;
        this.property.equipment = this.property.equipment.sort((a, b) => {
          return (
            this.equipments.map((item) => item.value).indexOf(a) -
            this.equipments.map((item) => item.value).indexOf(b)
          );
        });
        this.currentColor = response.data.isFavourite ? "red" : "grey";

        this.src = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCPYX-df-wDth7pRnxf_L8SNIaedHeK-Mo&q=${this.property.address}`;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    formatMoney,
    clickImg(index) {
      this.slideIndex = index;
    },
    addToFavourite() {
      axiosConfig
        .get("/user/favourite/" + this.property._id)
        .then((response) => {
          this.currentColor = response.data.isFavourite ? "red" : "grey";
        });
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState(["token"]),
  },
};
</script>

<style lang="scss" scoped>
.detail__short-desc {
  .detail__short-desc-point {
    display: flex;
    align-items: center;
  }
  .circle {
    border: 1px solid;
    padding: 8px;
    text-align: center;
    font: 14px Arial, sans-serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detail__short-desc-option {
    .buttons {
      display: flex;
      width: fit-content;
      gap: 10px;
      flex-wrap: wrap;
      align-content: flex-start;
    }
    .btn {
      border-radius: 1.2em;
      text-transform: capitalize;
    }
  }
}
.carousel_images {
  margin-left: -20px;
  margin-right: -20px;
  .carousel_image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.detail__slide {
  .carousel-item {
    height: 500px !important;
    overflow: hidden;
  }
  .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.detail_header {
  background-image: linear-gradient(to right, var(--success), white);
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 1rem;
  h3 {
    padding-left: 80px;
  }
}
.icon {
  position: absolute;
  left: -12px;
  background: white;
  border: 1px solid var(--success);
  padding: 0.6rem;
  border-radius: 50%;
}
.card-body {
  padding-left: 0;
  padding-right: 0;
}

// property details styles

.details_wrapper {
  border-bottom: 1px dashed var(--success);
  p {
    margin: 0;
    padding: 0.5rem;
    text-transform: capitalize;
  }
  .col-3,
  .col-9 {
    border-top: 1px dashed var(--success);
    padding: 0px;
  }
  .col-3 {
    border-right: 3px solid var(--success);
    background-color: var(--bg);
    white-space: nowrap;
  }
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--success);
  display: inline-block;
  margin-right: 5px;
}

// the map

#map {
  width: 100%;
  height: 400px;
  background-color: var(--map-bg);
}

.contact {
  border: 0 !important;
  .card-body {
    border: 3px solid var(--success);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 0;
  }
  .card-header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

.surrounding_content {
  &::after {
    content: "、";
  }
  &:last-child::after {
    content: none;
  }
}
@media screen and (min-width: 992px) {
  .circle {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    word-break: break-all;
  }
  .equipment {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
  }
  // contact card
  .detail__contact .card {
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1024px) {
  .carousel-item {
    max-height: 400px !important;
  }
}
@media screen and (max-width: 992px) {
  .col-3 {
    font-size: 0.8rem;
  }
  .carousel-item {
    max-height: 400px !important;
    overflow: hidden;
  }
  .detail_header {
    margin-left: 13px;
    h3 {
      font-size: 1.25rem !important;
    }
  }
  .detail__contact {
    .contact__button {
      padding: 8px 30px;
    }
    .h3 {
      font-size: 1.25rem !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .carousel-item {
    max-height: 220px !important;
    overflow: hidden;
  }
}
</style>
